import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { Stack } from '@mui/material';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
    <Typography variant="h5" id="skills" style ={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
        marginTop: 25,
        fontWeight:600
    }}>
        My Skills
    </Typography>
    <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{mb:4}}
            
        >
            <Grid item xs={10} sm={6}>
    <Box sx={{ bgcolor: 'background.paper', maxWidth: { xs: 320, sm: 480 },flexGrow: 1}}>

      {/* <AppBar position="static"> */}
        <Tabs
          value={value}
          onChange={handleChange}
        //   indicatorColor="secondary"
        //   textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Frontend" {...a11yProps(0)} />
          <Tab label="Backend" {...a11yProps(1)} />
          <Tab label="Libraries" {...a11yProps(2)} />
          <Tab label="Databases" {...a11yProps(3)} />
        </Tabs>
      {/* </AppBar> */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
            <Stack direction={'row'} justifyContent={'center'} spacing={2}>
                <Avatar
                    alt="Remy Sharp"
                    src="/html5.svg"
                    sx={{ width: 56, height: 56, }}
                    />

                <Avatar
                    alt="Remy Sharp"
                    src="/css3.svg"
                    sx={{ width: 56, height: 56, }}
                    />
            </Stack>
            <Stack direction={'row'} justifyContent={'center'} spacing={2} sx={{mt:2}}>
                <Avatar
                    alt="Remy Sharp"
                    src="/flutter.svg"
                    sx={{ width: 56, height: 56, }}
                    />

                
                <Avatar
                    alt="Remy Sharp"
                    src="/dart.svg"
                    sx={{ width: 56, height: 56, }}
                    />
        </Stack>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
        <Avatar
            alt="Remy Sharp"
            src="/javascript.svg"
            sx={{ width: 56, height: 56, }}
            />

        <Avatar
            alt="Remy Sharp"
            src="/nodejs.svg"
            sx={{ width: 56, height: 56, }}
            />

        <Avatar
            alt="Remy Sharp"
            src="/express.svg"
            sx={{ width: 56, height: 56, }}
            />
        </Stack>
        <Stack direction={'row'} justifyContent={'center'} spacing={2} sx={{mt:2}}>
        <Avatar
            alt="Remy Sharp"
            src="/java.svg"
            sx={{ width: 56, height: 56, }}
            />

        <Avatar
            alt="Remy Sharp"
            src="/spring-initializr.svg"
            sx={{ width: 56, height: 56, }}
            />
        </Stack>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
      <Avatar
            alt="Remy Sharp"
            src="/react.svg"
            sx={{ width: 56, height: 56, }}
            />
        </Stack>  
        <Stack direction={'row'} justifyContent={'center'} spacing={2} sx={{mt:2}}>
        <Avatar
            alt="Remy Sharp"
            src="/bootstrap.svg"
            sx={{ width: 56, height: 56}}
            />

        <Avatar
            alt="Remy Sharp"
            src="/mui.svg"
            sx={{ width: 56, height: 56}}
            />
        </Stack>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
      <Avatar
            alt="Remy Sharp"
            src="/mongodb.svg"
            sx={{ width: 56, height: 56, }}
            />

        <Avatar
            alt="Remy Sharp"
            src="/posgres.svg"
            sx={{ width: 56, height: 56, }}
            />
        </Stack>
        <Stack direction={'row'} justifyContent={'center'} spacing={2} sx={{mt:2}}>
      <Avatar
            alt="Remy Sharp"
            src="/firebase.svg"
            sx={{ width: 56, height: 56, }}
            />
        </Stack>
        </TabPanel>
      </SwipeableViews>
    </Box>
    </Grid>
    </Grid>
    </>
  );
}
