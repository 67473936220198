import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CloseIcon from '@mui/icons-material/Close';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";


const withLink = (to, children) => <Link to={to}>{children}</Link>;

const actions = [
  { icon: withLink("https://github.com/sanchezbarry", <GitHubIcon sx={{color:'white', paddingTop: 0.9}} />), name: 'GitHub',},
  { icon: withLink("https://www.linkedin.com/in/sanchez-barry/",<LinkedInIcon sx={{color:'white', paddingTop: 0.9}}/>), name: 'LinkedIn' },
  { icon: withLink("mailto:sanchezbarry@gmail.com",<EmailIcon sx={{color:'white', paddingTop: 0.9}}/>), name: 'Email' },
  { icon: withLink("https://wa.me/6596962639",<WhatsAppIcon sx={{color:'white', paddingTop: 0.9}}/>), name: 'WhatsApp' },
];

const style = {
  margin: 0,
  right: 20,
  bottom: 20,
  position: 'fixed',
  bgcolor: 'black'
};

export default function FloatingButton() {
  return (
    
      <SpeedDial
        ariaLabel="SpeedDial openIcon"
        style={style}
        sx={{ '& .MuiFab-primary': { backgroundColor: 'black', color: 'white' } }}
        icon={<SpeedDialIcon openIcon={<CloseIcon   />} />}
      >
        {actions.map((action) => (
          
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            sx={{width: 50, height: 50, alignContent: 'center', display: 'inline-flex', bgcolor: 'black'}}
          />
        ))}
      </SpeedDial>
  )

  
}