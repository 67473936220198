import React from 'react'
import Avatar from '@mui/material/Avatar';
import TypeIt from "typeit-react";
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { motion } from "framer-motion";
import Button from '@mui/material/Button';

export default function IntroSection() {
  return (
    <>
        <div style= {{
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            {/* <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                duration: 0.8,
                delay: 0.1,
                ease: [0, 0.71, 0.2, 1.01]
                }}
            > */}
                <Avatar
                alt="Sanchez Barry"
                src="/photo.png"
                sx={{ width: 200, height: 200 }}
                />
            {/* </motion.div> */}
        </div>

        <div style= {{
            marginTop: 20,
            marginBottom: 7,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ rotate: 360, scale: 1 }}
                transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                duration: 10,
                delay: 0.3,
                }}
            >
                <Typography variant="h4" sx={{fontWeight:800}}>Hi, I'm Sanchez.</Typography>
            </motion.div>
        </div>

        <div style= {{
            fontFamily: 'Poppins',
            fontSize: 20,
            marginTop: 5,
            marginBottom: 7,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
        <TypeIt
        options={{
            speed: 40
        }}
        getBeforeInit={(instance) => {
            instance.type("I'm a Software Engineer.").pause(750).delete(18).pause(500).type("Full Stack Developer.")
            .pause(750).delete(21).pause(500).type("Front-End Tinkerer.")
            .pause(750).delete(19).pause(500).type("Back-End Builder.")
            .pause(750).delete(17).pause(500).type("Software Engineer.");

            // Remember to return it!
            return instance;
        }}
        />;
        </div>



        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >

        <Grid item xs={10} sm={6} >
        <div style= {{

                    marginTop: 15,
                    marginBottom: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <motion.div

      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.8,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    ><Typography variant="p" sx={{justifyContent: 'center', color:'text.secondary', textAlign: 'left'}}>A marketer turned software engineer, after discovering my love for coding after dabbling with a few websites. I'm excited to use my experience as a marketer to build apps that are intuitive and feel like second nature to use.</Typography></motion.div>
        
                </div>  
            </Grid>

            <Grid item xs={10} sm={6} >
        <div style= {{

                    marginTop: 15,
                    marginBottom: 36,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
        <motion.div

initial={{ opacity: 0, scale: 0.5 }}
animate={{ opacity: 1, scale: 1 }}
transition={{
  duration: 0.8,
  delay: 0.8,
  ease: [0, 0.71, 0.2, 1.01]
}}
>
                    <Typography variant="p" sx={{justifyContent: 'center', color:'text.secondary', textAlign: 'left'}}>Here some <Button size="small" href="#projects">things</Button> I've created, and the <Button size="small" href="#skills">technologies</Button> I'm familiar with.</Typography></motion.div>
                    </div>
                    </Grid>
    
        </Grid> 

        
</>
  )
}
