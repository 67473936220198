import React from 'react'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Typography, Button } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import Link from '@mui/material/Link'
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { motion } from "framer-motion";
import { Stack } from '@mui/material';


export default function CTA() {
  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
>
<Grid item xs={10} sm={6}>
        <div style= {{

                    marginTop: 20,
                    marginBottom: 15,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Typography variant="h5" sx={{fontWeight:800, justifyContent: 'center', color:'text.secondary', textAlign: 'center'}}>Get in Touch</Typography>
                    
                 </div>
            </Grid>   
            <Grid item xs={10} sm={6}>
        <div style= {{

                    marginBottom: 25,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Typography variant="p" sx={{justifyContent: 'center', color:'text.secondary', textAlign: 'center'}}>My inbox is always open - whether you want to work together, or simply have a chat.</Typography>

                </div>  
            </Grid> 

            <Grid item xs={10} sm={6}>
        <div style= {{

                    marginBottom: 25,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                            <motion.div
                    className="box"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    ><Button variant="contained" href="mailto:sanchezbarry@gmail.com" sx={{borderRadius: 35}}startIcon={<EmailIcon />}>
                    {/* <Link sx={{textDecoration: 'none', color: 'inherit'}} > */}
                        Say Hi
                        {/* </Link> */}
                    </Button></motion.div>
                </div>  
            </Grid> 

            <Grid item xs={10} sm={6}>
                <div style= {{

                            marginBottom: 25,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                    <Stack direction={'row'} spacing={3}>
                
                    <motion.div
                    className="box"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <Link sx={{textDecoration: 'none', color: 'inherit'}} href="https://github.com/sanchezbarry"><GitHubIcon/></Link>
                    </motion.div>    

                    <motion.div
                    className="box"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <Link sx={{textDecoration: 'none', color: 'inherit'}} href="https://www.linkedin.com/in/sanchez-barry/"><LinkedInIcon/></Link>
                    </motion.div>  

                    <motion.div
                    className="box"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <Link sx={{textDecoration: 'none', color: 'inherit'}} href="https://wa.me/6596962639"><WhatsAppIcon/></Link>
                    </motion.div>  
                    </Stack>
                       
                </div>  
            </Grid> 

 


    </Grid>
  )
}
