import React from 'react'
import NavBar from '../components/NavBar';
import FloatingButton from '../components/FloatingButton';
import IntroSection from '../components/IntroSection';
import ProjectCards from '../components/ProjectCards';
import Skills from '../components/Skills';
import Skills2 from '../components/Skills2';
import CTA from '../components/CTA';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <>
        <NavBar />
        <IntroSection />
        
        <FloatingButton />
        <ProjectCards />
        {/* <Skills /> */}
        <Skills2 />
        <CTA />
        <Footer />
    </>
  )
}
