import React from 'react'
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <Box sx={{ width: 'full', bgcolor: 'text.disabled', color: 'text.secondary'}}>
    <BottomNavigation>
       <Typography style ={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        }}>
            Built & Designed by&nbsp;<Link style ={{textDecoration: 'none'}}to="https://github.com/sanchezbarry"> Sanchez</Link>&nbsp;© 2023 Copyright</Typography>
    </BottomNavigation>
  </Box>
  )
}
