import React from 'react'
import NavBar from '../components/NavBar'
import { Typography } from '@mui/material'
import FloatingButton from '../components/FloatingButton';
import Footer from '../components/Footer';


export default function Blog() {
  return (
    <>
        <NavBar />
        <FloatingButton/>
        <Typography sx={{textAlign: 'center'}}>Under Construction.</Typography>
        <Footer/>
    </>
  )
}
