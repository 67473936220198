import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Chip } from '@mui/material';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import { motion } from "framer-motion";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

export default function ProjectCards() {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [isOpen3, setIsOpen3] = React.useState(false)
  const [isOpen4, setIsOpen4] = React.useState(false)
  const [isOpen5, setIsOpen5] = React.useState(false)
  const [isOpen6, setIsOpen6] = React.useState(false)
    
  return (
    <>
    <SwipeableDrawer
      anchor='left'
      open={isOpen}
      onClose={() => setIsOpen(false)}>
        <Box sx={(theme) => ({
      [theme.breakpoints.down("md")]: {
        width: "100vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "40vw",
      },
      })}>

    <CloseIcon sx={{display: 'flex', alignItems: 'right', justifyContent: 'flex-right', marginLeft: 'auto', mr: 1, mt: 1}  } onClick={() => setIsOpen(false)}></CloseIcon>
    <Typography gutterBottom variant="h4" component="div" sx={{display: 'flex', alignItems: 'center',justifyContent: 'center', fontWeight: 600, marginTop: 2}}>
            Tipsy Bartender
        </Typography>
        <Card sx={{ maxWidth: "100vw", m:2}}>
            <CardMedia
            component="img"
            alt="green iguana"
            height="225"
            image="/tipsy.gif"
          />
            <CardContent>
            
            <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
                Tipsy Bartender
            </Typography>
            <Typography variant="h6"  sx={{mt: 1}}>Tech</Typography>
            <Stack direction="row" spacing={1} sx={{mt: 0.5}}>
            <Chip label="JavaScript" size="small" sx ={{}}/>
            <Chip label="HTML" size="small" sx ={{}}/>
            <Chip label="API Calls" size="small" sx ={{}}/>
            </Stack>
            <Stack direction="row" spacing={1} sx={{mt:1}}>
            <Chip label="CSS (Bootstrap)" size="small" sx ={{}}/>
            
            </Stack>
           
            <Typography variant="h6"  sx={{mt: 1}}>About</Typography>
            <Typography variant="body2" color="text.secondary">
            This website makes use of an API database (https://www.thecocktaildb.com/) to recommend drink recipes based on what booze you have lying around!
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" href="https://sanchezbarry.github.io/sanchezbarry/#" target="_blank">View</Button>
            <Button size="small" href="https://github.com/sanchezbarry/sanchezbarry" target="_blank">GitHub</Button>
          </CardActions>
        </Card>
    </Box>
</SwipeableDrawer>

<SwipeableDrawer
      anchor='left'
      open={isOpen2}
      onClose={() => setIsOpen2(false)}>
        <Box sx={(theme) => ({
      [theme.breakpoints.down("md")]: {
        width: "100vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "40vw",
      },
      })}>

    <CloseIcon sx={{display: 'flex', alignItems: 'right', justifyContent: 'flex-right', marginLeft: 'auto', mr: 1, mt: 1}  } onClick={() => setIsOpen2(false)}></CloseIcon>
    <Typography gutterBottom variant="h4" component="div" sx={{display: 'flex', alignItems: 'center',justifyContent: 'center', fontWeight: 600, marginTop: 2}}>
    Bean there, Done that.
        </Typography>
        <Card sx={{ maxWidth: "100vw", m:2}}>
        <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/coffee.gif"
      />

      <CardContent>
      
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
        Bean there, Done that.
        </Typography>
        <Typography variant="h6"  sx={{mt: 1}}>Tech</Typography>
        <Stack direction="row" spacing={1} sx={{mt: 0.5}}>
        <Chip label="MongoDB" size="small" />
        <Chip label="Express JS" size="small" />
        <Chip label="Node JS" size="small" />
        </Stack>
        <Stack direction="row" spacing={1} sx={{mt: 1}}>
        <Chip label="JavaScript" size="small" />
        <Chip label="CSS (Bootstrap)" size="small"/>
        <Chip label="CRUD" size="small" />
        </Stack>
        <Typography variant="h6"  sx={{mt: 1}}>About</Typography>
        <Typography variant="body2" color="text.secondary">
        Coffee has many, many permutations. From the bean used, to the grind size, the temperature, time and more! This website helps baristas and coffee enthusiats alike to keep track of what permutations they've done, and what works best. This website features a full CRUD and user authentication system. Users can read, create, edit and delete recepies. Note: This website takes awhile to load, please be patient!</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href="https://coffee-cu87.onrender.com/" target="_blank">View</Button>
        <Button size="small" href="https://github.com/sanchezbarry/coffee" target="_blank">GitHub</Button>
      </CardActions>
    </Card>
    </Box>
</SwipeableDrawer>

<SwipeableDrawer
      anchor='left'
      open={isOpen3}
      onClose={() => setIsOpen3(false)}>
        <Box sx={(theme) => ({
      [theme.breakpoints.down("md")]: {
        width: "100vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "40vw",
      },
      })}>

    <CloseIcon sx={{display: 'flex', alignItems: 'right', justifyContent: 'flex-right', marginLeft: 'auto', mr: 1, mt: 1}  } onClick={() => setIsOpen3(false)}></CloseIcon>
    <Typography gutterBottom variant="h4" component="div" sx={{display: 'flex', alignItems: 'center',justifyContent: 'center', fontWeight: 600, marginTop: 2}}>
    Software Engineered
        </Typography>
        <Card sx={{ maxWidth: "100vw", m:2}}>
        <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/software.gif"
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
        Software Engineered
        </Typography>
        <Typography variant="h6"  sx={{mt: 1}}>Tech</Typography>
        <Stack direction="row" spacing={1} sx={{mt: 0.5}}>
        <Chip label="MongoDB" size="small" />
        <Chip label="Express JS" size="small" />
        <Chip label="React JS" size="small" />
        </Stack>
        <Stack direction="row" spacing={1} sx={{mt: 1}}>
        <Chip label="Node JS" size="small" />
        <Chip label="MERN" size="small" />
        <Chip label="CRUD" size="small" />
        <Chip label="API Calls" size="small" />
        </Stack>
        <Typography variant="h6"  sx={{mt: 1}}>About</Typography>
        <Typography variant="body2" color="text.secondary">
        Software-Engineered is a job-portal that pulls in various APIs from other job-portals like Nodeflair and other people who create jobs in the portal to display to potential job seeking software engineers. This job portal has the best of multiple job portals as it aggregates them and takes in other peoples created job to give a plethora of job opportunities for anyone interested in the field of tech. Backend is temporarily down.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href="https://lunfy.github.io/Software-Engineer-Job-Portal-Frontend/" target="_blank">View</Button>
        <Button size="small" href="https://github.com/sanchezbarry/Software-Engineer-Job-Portal-Frontend" target="_blank">GitHub</Button>
      </CardActions>
    </Card>
    </Box>
</SwipeableDrawer>

<SwipeableDrawer
      anchor='left'
      open={isOpen4}
      onClose={() => setIsOpen4(false)}>
        <Box sx={(theme) => ({
      [theme.breakpoints.down("md")]: {
        width: "100vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "40vw",
      },
      })}>

    <CloseIcon sx={{display: 'flex', alignItems: 'right', justifyContent: 'flex-right', marginLeft: 'auto', mr: 1, mt: 1}  } onClick={() => setIsOpen4(false)}></CloseIcon>
    <Typography gutterBottom variant="h4" component="div" sx={{display: 'flex', alignItems: 'center',justifyContent: 'center', fontWeight: 600, marginTop: 2}}>
    Workouteer (Mobile App)
        </Typography>
        <Card sx={{ maxWidth: "100vw", m:2}}>
        <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/workout.png"
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
            Workouteer (Mobile App)
        </Typography>
        <Typography variant="h6"  sx={{mt: 1}}>Tech</Typography>
        <Stack direction="row" spacing={1} sx={{mt: 0.5}}>
        <Chip label="Flutter" size="small" />
        <Chip label="Dart" size="small" />
        <Chip label="Firebase" size="small" />
        </Stack>
        <Stack direction="row" spacing={1} sx={{mt: 1}}>
        <Chip label="Firestore" size="small" />
        <Chip label="CRUD" size="small" />
        </Stack>
        <Typography variant="h6"  sx={{mt: 1}}>About</Typography>
        <Typography variant="body2" color="text.secondary">
            For my capstone project at GA, I wanted to challenge myself and create something completely new from the course. Workouter is a mobile app built on Flutter with a full CRUD, and authentication with Firebase.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href="https://github.com/sanchezbarry/Workouter-App" target="_blank">GitHub</Button>
      </CardActions>
    </Card>
    </Box>
</SwipeableDrawer>

<SwipeableDrawer
      anchor='left'
      open={isOpen5}
      onClose={() => setIsOpen5(false)}>
        <Box sx={(theme) => ({
      [theme.breakpoints.down("md")]: {
        width: "100vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "40vw",
      },
      })}>

    <CloseIcon sx={{display: 'flex', alignItems: 'right', justifyContent: 'flex-right', marginLeft: 'auto', mr: 1, mt: 1}  } onClick={() => setIsOpen5(false)}></CloseIcon>
    <Typography gutterBottom variant="h4" component="div" sx={{display: 'flex', alignItems: 'center',justifyContent: 'center', fontWeight: 600, marginTop: 2}}>
    CCK Sermons
        </Typography>
        <Card sx={{ maxWidth: "100vw", m:2}}>
        <Link href="https://cck.org.sg/sermons.html" target="_blank">
      <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/church.gif"
      />
      </Link>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
            CCK Sermons
        </Typography>
        <Typography variant="h6"  sx={{mt: 1}}>Tech</Typography>
        <Stack direction="row" spacing={1} sx={{mt: 0.5}}>
        <Chip label="JavaScript" size="small" />
        <Chip label="HTML" size="small" />
        <Chip label="CSS (Bootstrap)" size="small"/>
        </Stack>
        <Stack direction="row" spacing={1} sx={{mt: 1}}>
        <Chip label="API calls" size="small" />
        </Stack>
        <Typography variant="h6"  sx={{mt: 1}}>About</Typography>
        <Typography variant="body2" color="text.secondary">
        A simple one-pager for a church's sermons to be hosted and played. The sermons are also searchable by sermon title, and a random verse can also be generated.
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" href="https://cck.org.sg/sermons.html" target="_blank">View</Button>
        <Button size="small" href="https://github.com/sanchezbarry/cck" target="_blank">GitHub</Button>
        </CardActions>
    </Card>
    </Box>
</SwipeableDrawer>

<SwipeableDrawer
      anchor='left'
      open={isOpen6}
      onClose={() => setIsOpen6(false)}>
        <Box sx={(theme) => ({
      [theme.breakpoints.down("md")]: {
        width: "100vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "40vw",
      },
      })}>

    <CloseIcon sx={{display: 'flex', alignItems: 'right', justifyContent: 'flex-right', marginLeft: 'auto', mr: 1, mt: 1}  } onClick={() => setIsOpen6(false)}></CloseIcon>
    <Typography gutterBottom variant="h4" component="div" sx={{display: 'flex', alignItems: 'center',justifyContent: 'center', fontWeight: 600, marginTop: 2}}>
    Sanchez Barry's Website
        </Typography>
        <Card sx={{ maxWidth: "100vw", m:2}}>
        <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/sanchez.gif"
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
            sanchezbarry.com (This Website)
        </Typography>
        <Typography variant="h6"  sx={{mt: 1}}>Tech</Typography>
        <Stack direction="row" spacing={1} sx={{mt: 0.5}}>
        <Chip label="React" size="small" />
        <Chip label="MUI" size="small" />
        <Chip label="GH-Pages" size="small" />
        </Stack>
        <Stack direction="row" spacing={1} sx={{mt: 1}}>
        <Chip label="JSX" size="small" />
        </Stack>
        <Typography variant="h6"  sx={{mt: 1}}>About</Typography>
        
        <Typography variant="body2" color="text.secondary">
        This portfolio website to show off my skills and projects. I had also wanted to attempt using a custom domain for the first time. 
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" href="https://sanchezbarry.com/" target="_blank">View</Button>
        <Button size="small" href="https://github.com/sanchezbarry/portfolio/tree/gh-pages" target="_blank">GitHub</Button>
        </CardActions>
    </Card>
    </Box>
</SwipeableDrawer>

<motion.div

initial={{ opacity: 1, scale: 0.5, y: -10}}
animate={{ opacity: 1, scale: 1, y: 20 }}
transition={{
  repeat: Infinity,
  repeatType: "reverse",
  duration: 1,
  delay: 0.1,
  ease: [0, 0.71, 0.2, 1.01]
}}
><ArrowDownwardRoundedIcon id="projects" style ={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    marginBottom: 65,
    fontSize: 65

}}/></motion.div>


<motion.div

initial={{ opacity: 0, scale: 0.5 }}
whileInView={{ opacity: 1, scale: 1 }}
viewport={{ once: true}}
transition={{
  duration: 0.8,
  delay: 0.1,
  ease: [0, 0.71, 0.2, 1.01]
}}
><Typography  variant="h5" style ={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
    fontWeight:600
}}>
    My Projects
</Typography>

<Typography variant="h7" style ={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
    fontWeight:400
}}>
    Click on them to read more!
</Typography></motion.div>

<Box sx={{ flexGrow: 1 }} style={{
    marginLeft: 15,
    marginRight: 15
}}>

<Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

    <Grid xs={4} sm={4} md={3} style ={{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,

}}>
        <motion.div
      className="box"
      // initial="offscreen"
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true}}
      transition={{
        duration: 0.9,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    ><motion.div
    className="box"
    whileHover={{ scale: 1.1 }}
    transition={{ type: "spring", stiffness: 400, damping: 10 }}
    whileTap={{ scale: 0.9 }}
  ><Card sx={{ maxWidth: 345, cursor:"pointer" }} onClick={() => setIsOpen(true)}>


      <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/tipsy.gif"
      />

      <CardContent>
        
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
            Tipsy Bartender
        </Typography>
        <Chip label="JavaScript" size="small" sx ={{mr :1 }}/>
        <Chip label="HTML" size="small" sx ={{mr :1 }}/>
        <Chip label="CSS (Bootstrap)" size="small" sx ={{mr :1 }}/>
        <Chip label="API Calls" size="small" sx ={{mr :1, mt:1 }}/>
        <Typography variant="body2" color="text.secondary" sx={{mt: 1.5}}>
          Got alcohol but no inspiration?
        </Typography>
      </CardContent>

    </Card></motion.div></motion.div>
        </Grid>

        <Grid xs={4} sm={4} md={3} style ={{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25
}}>
        <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true}}
      transition={{
        duration: 0.9,
        delay: 0.6,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    ><motion.div
    className="box"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    transition={{ type: "spring", stiffness: 400, damping: 10 }}
  ><Card sx={{ maxWidth: 345, cursor:"pointer" }} onClick={() => setIsOpen2(true)}>

      <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/coffee.gif"
      />

      <CardContent>
      
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
        Bean there, Done that.
        </Typography>

        <Chip label="MongoDB" size="small" sx ={{mr :1 }}/>
        <Chip label="Express JS" size="small" sx ={{mr :1 }}/>
        <Chip label="Node JS" size="small" sx ={{mr :1 }}/>
        <Chip label="JavaScript" size="small" sx ={{mr :1, mt: 1 }}/>
        <Chip label="CSS (Bootstrap)" size="small" sx ={{mr :1, mt: 1  }}/>
        <Chip label="CRUD" size="small" sx ={{mr :1, mt: 1  }}/>
        <Typography variant="body2" color="text.secondary" sx={{mt: 1.5}}>
        Coffee has many, many permutations.</Typography>
      </CardContent>

    </Card></motion.div></motion.div>
        </Grid>

    <Grid xs={4} sm={4} md={3} style ={{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25
}}>
        <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true}}
      transition={{
        duration: 0.9,
        delay: 0.7,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    ><motion.div
    className="box"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    transition={{ type: "spring", stiffness: 400, damping: 10 }}
  ><Card sx={{ maxWidth: 345 , cursor:"pointer" }} onClick={() => setIsOpen3(true)}>

      <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/software.gif"
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
        Software Engineered
        </Typography>
        <Chip label="MongoDB" size="small" sx ={{mr :1 }}/>
        <Chip label="Express JS" size="small" sx ={{mr :1 }}/>
        <Chip label="React JS" size="small" sx ={{mr :1 }}/>
        <Chip label="Node JS" size="small" sx ={{mr :1, mt: 1 }}/>
        <Chip label="MERN" size="small" sx ={{mr :1, mt: 1 }}/>
        <Chip label="CRUD" size="small" sx ={{mr :1, mt: 1 }}/>
        <Chip label="API Calls" size="small" sx ={{mr :1, mt: 1 }}/>

        <Typography variant="body2" color="text.secondary"  sx ={{ mt: 1.5 }}>
          A job portal of all job portals.
        </Typography>
      </CardContent>
    </Card></motion.div></motion.div>
        </Grid>

        <Grid xs={4} sm={4} md={3} style ={{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25
}}>
        <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true}}
      transition={{
        duration: 0.9,
        delay: 0.8,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    ><motion.div
    className="box"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    transition={{ type: "spring", stiffness: 400, damping: 10 }}
  ><Card sx={{ maxWidth: 345 , cursor:"pointer" }} onClick={() => setIsOpen4(true)}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/workout.png"
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
            Workouteer (App)
        </Typography>

        <Chip label="Flutter" size="small" sx ={{mr :1 }}/>
        <Chip label="Dart" size="small" sx ={{mr :1 }}/>
        <Chip label="Firebase" size="small" sx ={{mr :1 }}/>
        <Chip label="Firestore" size="small" sx ={{mr :1}}/>
        <Chip label="CRUD" size="small" sx ={{mr :1, mt: 1  }}/>

        <Typography variant="body2" color="text.secondary"  sx ={{ mt: 1.5 }}>
            An app to plan, track and build workouts.
        </Typography>
      </CardContent>
    </Card></motion.div></motion.div>
        </Grid>

        <Grid xs={4} sm={4} md={3} style ={{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25
}}>
        <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true}}
      transition={{
        duration: 0.4,
        delay: 0.9,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    ><motion.div
    className="box"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    transition={{ type: "spring", stiffness: 400, damping: 10 }}
  ><Card sx={{ maxWidth: 345 , cursor:"pointer" }} onClick={() => setIsOpen5(true)}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/church.gif"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
            CCK Sermons
        </Typography>

        <Chip label="JavaScript" size="small" sx ={{mr :1 }}/>
        <Chip label="HTML" size="small" sx ={{mr :1 }}/>
        <Chip label="CSS (Bootstrap)" size="small" sx ={{mr :1 }}/>
        <Chip label="API calls" size="small" sx ={{mr :1, mt: 1 }}/>

        <Typography variant="body2" color="text.secondary"  sx ={{ mt: 1.5 }}>
        A simple one-pager for sermons to be hosted and played. 
        </Typography>
      </CardContent>
    </Card></motion.div></motion.div>
        </Grid>

        <Grid xs={4} sm={4} md={3} style ={{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25
}}>
        <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true}}
      transition={{
        duration: 0.5,
        delay: 1.1,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    ><motion.div
    className="box"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    transition={{ type: "spring", stiffness: 400, damping: 10 }}
  ><Card sx={{ maxWidth: 345 , cursor:"pointer" }} onClick={() => setIsOpen6(true)}>

      <CardMedia
        component="img"
        alt="green iguana"
        height="225"
        image="/sanchez.gif"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 600}}>
            sanchezbarry.com (This Website)
        </Typography>

        <Chip label="React" size="small" sx ={{mr :1 }}/>
        <Chip label="MUI" size="small" sx ={{mr :1 }}/>
        <Chip label="GH-Pages" size="small" sx ={{mr :1 }}/>
        <Chip label="JSX" size="small" sx ={{mr :1}}/>

        <Typography variant="body2" color="text.secondary"  sx ={{ mt: 1.5 }}>
        A website to show off my skills and projects.
        </Typography>
      </CardContent>

    </Card></motion.div></motion.div>
        </Grid>
      </Grid>
      {/* </motion.ul> */}
    </Box>
    
    </>
  );
}