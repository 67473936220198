import { grey, common } from '@mui/material/colors';

const palette = {
  light: {
    primary: {
      main: '#909090',
      light: '#B1DED3',
      dark: '#909090',
    },
  },
};

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: palette.light.primary.main,
            light: palette.light.primary.light,
            dark: palette.light.primary.dark,
          },

          divider: grey[200],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          primary: grey,
          divider: grey[700],
          background: {
            default: grey[900],
            paper: grey[900],
          },
          text: {
            primary: '#fff',
            secondary: grey[200],
          },
        }),
  },
  typography: {
    fontFamily: [
      'Poppins'

    ].join(','),
    body1: {
      fontFamily: 'Poppins'
    },
  },
});

export const getThemedComponents = (mode) => ({
  components: {
    ...(mode === 'light'
      ? {

        // styleOverrides:{
        //     fontFamily: "Poppins"
        // },
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: grey[100],
              },
            },
          },
          MuiLink: {
            variant: 'h5',
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 0,    
                color: grey[900],
                fontFamily:
                  "Poppins",
                fontSize: 15,
                borderWidth: 2,
                '&:hover': {
                  borderWidth: 2,
                },
              },
            },
            variants: [
              {
                props: { variant: 'contained' },
                style: {
                  fontFamily:
                    "Poppins",
                },
              },
              {
                props: { variant: 'outlined' },
                style: {
                  color: palette.light.primary.main,
                },
              },
              {
                props: { variant: 'primary', color: 'primary' },
                style: {
                  border: '4px dashed blue',
                },
              },
            ],
          },
          MuiList: {
            styleOverrides: {
              root: {},
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                color: common.black,
                alignItems: 'stretch',
                fontFamily:
                  "Poppins",
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                color: common.black,
                fontFamily:
                  "Poppins",
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                color: common.black,
                fontFamily:
                  "Poppins",
                borderRadius: 10
              },
            },
          },
        }
      : 
    //   {styleOverrides:{
    //     fontFamily: "Poppins"
    // }},
      
      {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: grey[800],
              },
            },
          },

          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 0,    
                color: grey[100],
                fontFamily:
                  "Poppins",
                fontSize: 15,
                borderWidth: 2,
                '&:hover': {
                  borderWidth: 2,
                },
              },
            }},

            MuiCard: {
                styleOverrides: {
                  root: {
                    color: common.white,
                    fontFamily:
                      "Poppins",
                    borderRadius: 10,
                    // backgroundColor: common.black
                    // backgroundColor: '#1d1e1e'
                  },
                },
              },
        }),


  },
});