import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import { Button, Link } from '@mui/material';
import Switch from '@mui/material/Switch';
import ThemeToggler from './ThemeToggler';


function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function NavBar(props) {

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <HideOnScroll {...props}> */}
        <AppBar >
          <Toolbar sx={{bgcolor: 'background.default', color: 'text.primary'}}>
            <Link href='/'><LogoDevIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1}} /></Link>
          
          <Button sx={{display: { xs: 'flex', md: 'flex', justifyContent: 'right', fontFamily: 'Poppins', textTransform: 'lowercase' }}} href='/Sanchez_Resume_2023.pdf' target="_blank">Resume</Button>
          <Button sx={{display: { xs: 'flex', md: 'flex', flexDirection: 'right', fontFamily: 'Poppins', textTransform: 'lowercase'  }}} href='https://github.com/sanchezbarry' target="_blank">GitHub</Button>
          <Button sx={{display: { xs: 'flex', md: 'flex', flexDirection: 'right', fontFamily: 'Poppins', textTransform: 'lowercase'  }}} href='https://www.linkedin.com/in/sanchez-barry/' target="_blank">LinkedIn</Button>
          <Button sx={{display: { xs: 'flex', md: 'flex', flexDirection: 'right', fontFamily: 'Poppins', textTransform: 'lowercase'  }}} href='/blog'>Blog</Button>
          <ThemeToggler />
          </Toolbar>
        </AppBar>
      {/* </HideOnScroll> */}
      <Toolbar />
      <Container>
        <Box sx={{ my: 2 }}>

        </Box>
      </Container>
    </React.Fragment>
  );
}

