import React from 'react';
import NavBar from './components/NavBar';
import FloatingButton from './components/FloatingButton';
import IntroSection from './components/IntroSection';
import ProjectCards from './components/ProjectCards';
import Footer from './components/Footer';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { deepmerge } from '@mui/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getDesignTokens, getThemedComponents } from './components/Theme';
import { ColorModeContext } from './components/color-context';
import CTA from './components/CTA';
import Skills from './components/Skills';
import Skills2 from "./components/Skills2";
import Home from './pages/Home';
import { Routes, Route } from "react-router-dom"
import Blog from './pages/Blog';



function App(){
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState();

React.useEffect(() => {
  setMode(prefersDarkMode ? 'dark' : 'light');
}, [prefersDarkMode]);

const colorMode = React.useMemo(
  () => ({
    toggleColorMode: () => {
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    },
  }),
  []
);

let theme = React.useMemo(
  () =>
    createTheme(deepmerge(getDesignTokens(mode), getThemedComponents(mode))),
  [mode]
);

theme = responsiveFontSizes(theme);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <Routes>
            <Route path="/" element={ <Home/> } />
            <Route path="/blog" element={ <Blog/> } />
          </Routes>
        </div>


            {/* <NavBar />
            <IntroSection />
            <FloatingButton />
            <ProjectCards />
            <Skills />
            <Skills2 />
            <CTA />
            <Footer /> */}

      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
